<template>
  <div style="margin-top: 20px; margin-right: 30px">
    <div class="f-row nav-1">
      <div style="width: 10%">
        <p style="padding: 5px">所在省份</p>
      </div>
      <div class="df-wrap" style="width: 90%">
        <el-radio-group v-model="formdata.provinceId" size="small">
          <el-radio-button v-for="(item, index) in ProvinceList" :key="index" :label="item.id">{{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="f-row nav-1">
      <div style="width: 10%">
        <p style="padding: 5px">院校特性</p>
      </div>
      <div class="df-wrap" style="width: 90%">
        <el-radio-group size="small" v-model="formdata.collegeCharacter">
          <el-radio-button v-for="(item, index) in collegeCharacter" :key="index" :label="item.id">{{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div>
      <div class="f-row nav-1">
        <div style="width: 10%">
          <p style="padding: 5px">院校类型</p>
        </div>
        <div class="df-wrap" style="width: 90%">
          <el-radio-group v-model="formdata.disciplineAmbit" size="small">
            <el-radio-button v-for="(item, index) in disciplineAmbit" :key="index" :label="item.id">{{ item.name }}
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="nav-3">
      <div v-for="(item, index) in schoolList" :key="index" @click="godetail(item.universityId)" class="schoolcard">
        <el-card shadow="hover">
          <div class="f-row nav-2">
            <div style="width: 10%">
              <div class="img">
                <img style="width: 80px; height: 80px; border-radius: 40px" v-if="item.collegeBadge"
                  :src="'/com/file/fileDownLoad?filePath=' + item.collegeBadge" alt="" :onerror="smallImgError" />
                <img style="width: 80px; height: 80px; border-radius: 40px" v-else src="../../../assets/find/校徽.png"
                  alt="" />
              </div>
            </div>
            <div class="" style="width: 90%">
              <div class="schoolname">{{ item.collegeName }}</div>
              <div class="bo">
                <!-- {{item.collegeCharacter}} -->
                <span v-if="item.collegeCharacter.includes('1')" class="span span1">211</span>
                <span v-if="item.collegeCharacter.includes('2')" class="span span2">985</span>
                <span v-if="item.collegeCharacter.includes('3')" class="span span3">双一流</span>
              </div>
              <!-- <div class="text">
              {{ item.introduction }}
            </div> -->
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="pagination">
      <el-pagination background :hide-on-single-page="total <= 10" layout="prev, pager, next" :total="total"
        @current-change="handleChange" :current-page.sync="formdata.current" :page-size="formdata.size"></el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getProvinceList, //省份
  getCollegeList, //找学校
} from "@/api/dataquery";
export default {
  data() {
    return {
      total: 0,
      disciplineAmbit: [],
      collegeCharacter: [
        { name: "不限", id: "" },
        { name: "985", id: 1 },
        { name: "211", id: 2 },
        { name: "双一流", id: 3 },
      ],
      disciplineAmbit: [
        { name: "不限", id: 0 },
        { name: "综合", id: 1 },
        { name: "理工", id: 2 },
        { name: "文史", id: 3 },
        { name: "师范", id: 4 },
        { name: "教育", id: 5 },
        { name: "财经", id: 6 },
        { name: "艺术", id: 7 },
        { name: "农林", id: 8 },
        { name: "军事", id: 9 },
        { name: "民族", id: 10 },
        { name: "政法", id: 11 },
        { name: "医药", id: 12 },
        { name: "体育", id: 13 },
        { name: "预科", id: 14 },
        { name: "其他", id: 20 },
      ],
      ProvinceList: [],
      schoolList: [],
      formdata: {
        id: "",
        size: 10,
        current: 1,
        disciplineAmbit: 0,
        collegeCharacter: "",
      },
    };
  },
  watch: {
    formdata: {
      handler(val) {
        console.log(val);
        if (val) {
          this.findschool();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getProvice();
    this.findschool();
  },
  methods: {
    async getProvice() {
      let res = await getProvinceList({});
      if (res.code == 1) {
        this.ProvinceList = res.data || [];
        this.ProvinceList.unshift({
          name: "全国",
          parentId: "",
        });
        this.ProvinceList = this.ProvinceList.filter(item => {
          return item.name != "台湾省" && item.name != "香港特别行政区" && item.name != "澳门特别行政区"
        })
      }
    },
    handleChange(page) {
      this.formdata.current = page;
    },
    // 去详情
    godetail(universityId) {
      this.$router.push({
        path: "/dataQuery/findschool/schooldetail",
        query: { universityId: universityId },
      });
    },
    async findschool() {
      let res = await getCollegeList({
        ...this.formdata,
      });
      if (res.code == 1) {
        let data = res.data || {};
        this.total = data.total || 0;
        this.schoolList = data.records;
        // this.schoolList.forEach((item) => {
        //   item.collegeCharacter = item.collegeCharacter.split(",");
        //   console.log(item.collegeCharacter);
        // });
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1ab394;
}

/deep/.el-pager li:hover {
  color: #1ab394 !important;
}

.img {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  overflow: hidden;
}

.pagination {
  margin-top: 40px;
}

.f-row {
  width: 910px;
}

.nav-1 {
  span {
    padding: 10px;
    cursor: pointer;
  }

  span:hover {
    background-color: #1ab394;
    color: white;
  }
}

.nav-3 {}

/deep/.el-radio-button__inner {
  color: #666666 !important;
  width: 120px;
  height: 28px;
  line-height: 28px;
  padding: 0px !important;
  margin: 5px !important;
  border-radius: 5px !important;
}

/deep/.el-radio-button:first-child .el-radio-button__inner {
  border-left: 0px !important;
}

/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: #1ab394 !important;
  color: #fff !important;
}

/deep/.el-radio-button__inner {
  border: 0px !important;
}

.schoolname {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}

.text {
  width: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.schoolcard {
  margin: 10px 0px;
}

.bo {
  margin-top: 30px;

  .span {
    display: inline-block;
    width: 64px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 12px;
    margin-right: 10px;
  }

  .span1 {
    background-color: #fff9e0;
    color: #ef9a70;
  }

  .span2 {
    background-color: #e0ffe0;
    color: #65e365;
  }

  .span3 {
    background-color: #fff9e0;
    color: #ef9a70;
  }
}

.nav-1 {
  border-bottom: 1px dashed #f2f2f2;
}

.nav-1:last-child {
  border: none;
}
</style>